<template>
  <div class="newActivityBoxAll">
    <div class="activityMsgBox">
      <el-form
        ref="Activityform"
        :model="form"
        :rules="isRules"
        label-width="128px"
        label-position="left"
      >
        <div class="activityName">
          <el-form-item label="活动名称" prop="name">
            <el-input
              placeholder="请输入活动名称"
              maxlength="12"
              show-word-limit
              v-model="form.name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="activityTime">
          <el-form-item label="活动时间" prop="date1">
            <div class="dateBox">
              <el-date-picker
                :disabled="form.status == 2 || form.status == 3"
                v-model="form.date1[0]"
                format="yyyy-MM-dd HH:mm"
                :picker-options="pickerOptions"
                type="datetime"
                placeholder="选择开始时间"
              ></el-date-picker>
              <span class="dateBoxZ">至</span>
            </div>
          </el-form-item>
          <div class="dateBoxInput">
            <el-form-item label="" prop="date2">
              <div class="dateBox_1">
                <el-date-picker
                  v-model="form.date2[0]"
                  format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptionss"
                  type="datetime"
                  placeholder="选择结束时间"
                ></el-date-picker>
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="activityJoin">
          <el-form-item label="参与条件" prop="condition">
            <el-radio-group v-model="form.condition">
              <el-radio label="无需报名课程"></el-radio>
              <el-radio label="需报名课程"></el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="activityJoinChild">
            <p>
              无需报名：学员在课程详情或推广中心分享课程即可生成推广海报，更低门槛进行裂变传播，建议选择此项；
            </p>
          </div>
        </div>

        <div class="activityProportion">
          <el-form-item
            label="奖励分成比例"
            placeholder="请输入1-100整数"
            prop="proportion"
          >
            <el-input
              :min="0"
              :max="100"
              v-model="form.proportion"
              type="text"
              @input="toNumber"
            ></el-input>
            <span class="spanProportion">%</span>
          </el-form-item>
          <p>
            提示：推广人邀请学员成功购课，可获得的奖励佣金；比如一门100元的课程，分成比例为20%，即20/100*100=佣金
          </p>
        </div>
      </el-form>
    </div>
    <div class="activityListBox">
      <div class="title">
        <h3>关联内容({{ form.list.length }})</h3>
        <p>关联内容即成为推广内容，可按需求选择一或多个</p>
      </div>

      <div class="selectButton" @click="chooseCourse">
        <p>+选择内容</p>
      </div>
      <el-dialog
        title="选择课程"
        width="800px"
        :visible.sync="dialogSelectVisible"
        v-if="dialogSelectVisible"
      >
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程" name="1"></el-tab-pane>
          <el-tab-pane label="图文" name="2"></el-tab-pane>
        </el-tabs>
        <template v-if="activeName == 2">
          <data-contain
            @listSave="listSave"
            :ids="ids"
            :goodlistType="true"
          ></data-contain>
          <div class="tuwenSelect">
            <el-button size="medium" @click="dialogSelectVisible = false">
              取消
            </el-button>
            <el-button type="primary" size="medium" @click="graphicSubmit">
              确定
            </el-button>
          </div>
        </template>
        <template v-if="activeName == 1">
          <dialog-selectcourse
            ref="dialogRef"
            :goodlistType="true"
          ></dialog-selectcourse>
        </template>
      </el-dialog>

      <div class="activityList">
        <div class="noData" v-if="form.list.length == 0">
          <p>未选择内容</p>
        </div>
        <div class="list_1" v-for="(item, index) in form.list" :key="index">
          <div class="imgTitle">
            <img :src="item.photo || item.cover_img" alt="" />
            <p :title="item.name">{{ item.name }}</p>
          </div>
          <div class="menuRight">
            <p class="p" v-if="item.course_type">
              {{
                item.course_type == 1
                  ? '直播课'
                  : item.course_type == 2
                  ? '小班课'
                  : item.course_type == 3
                  ? '录播课'
                  : '系列课'
              }}
            </p>
            <p v-else class="p">
              图文
            </p>
            <span class="span">￥{{ item.price }}</span>
            <template>
              <el-popconfirm
                confirm-button-text="确定"
                placement="left"
                :offset="20"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                @confirm="moveSaveClass(index)"
                title="是否要移除该课程？"
              >
                <el-button type="text" slot="reference">移除</el-button>
              </el-popconfirm>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="buttonSave">
      <el-button type="primary" @click="onSubmit(form)">保存</el-button>
    </div>
  </div>
</template>
<script>
import dataContain from '@cm/base/networktypeSelect/graphic/dataContain'
import dialogSelectcourse from '../../components/base/dialogSelectcourse'
export default {
  components: {
    dialogSelectcourse,
    dataContain,
  },
  data() {
    // var checkTime = (rule, value, callback)=>{
    //   if (!this.form.date2[0]) {
    //     return callback(new Error('请选择日期'));
    //   }
    // }
    var jcNumber = (rule, value, callback) => {
      if (value > 100 || value == '' || value <= 0) {
        return callback(new Error('请输入1-100的数字'))
      } else {
        callback()
      }
    }
    return {
      visible: true,

      activeName: '1',

      dialogSelectVisible: false,
      form: {
        fission_id: '',
        name: '',
        date1: [],
        date2: [],
        condition: '无需报名课程',
        proportion: '20',
        list: [],
        status: '',
      },
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
      },
      pickerOptionss: {
        disabledDate: time => {
          // console.log(Date.now())
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          // if (Date.parse(this.form.date1[0]) >= Date.now()) {
          //   let sjc = Date.parse(this.form.date1[0])
          //   return time.getTime() <= sjc;
          // } else {
          //   return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          // }
        },
      },
      isRules: {
        name: [
          {
            required: true,
            message: '请输入活动名称',
            trigger: 'blur',
          },
        ],
        date1: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        date2: [
          {
            required: true,
            // validator:checkTime,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        condition: [
          {
            required: true,
            message: '请选择活动资源',
            trigger: 'change',
          },
        ],
        proportion: [
          {
            validator: jcNumber,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ids() {
      return this.form.list
        .filter(item => item.graphic_id)
        .map(item => item.graphic_id)
    },

    NdefaultTime() {
      // this.$options.filters['formatTimeStamp'](Date.now(), 'yyyy-MM-dd hh:mm')
      return this.$options.filters['formatTimeStamp'](
        Date.now() / 1000,
        'hh:mm:00'
      )
    },
  },
  beforeMount() {
    //判断id的值为new则是点击新建活动,不用获取活动数据//不改标题
    if (this.$route.params.id == 'new') {
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '新建活动'
    } else {
      this.form.fission_id = this.$route.params.id
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑活动'
    }
  },

  created() {
    this.getMsgData(this.$route.params.id)
  },
  methods: {
    listSave(val) {
      this.graphicSelect = val
    },

    graphicSubmit() {
      this.form.list.push(...this.graphicSelect)
      this.dialogSelectVisible = false
    },

    toNumber() {
      this.form.proportion = this.form.proportion.replace(/\D/g, '')
      if (this.form.proportion == 0) {
        this.form.proportion = ''
      }
    },

    handleClick(val) {
      if (val.index == 0) {
        this.chooseCourse()
      }
    },

    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
      this.activeName = '1'
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        // _this.$refs.dialogRef.getMenu()
        // console.log(this.form)
        const course = this.form.list.filter(item => item.course_id)
        _this.$refs.dialogRef.getMenu(course, _this.form.fission_id)
        _this.$refs.dialogRef.clearSelect()
        // this.$refs.childDialogBox.reset()
      })
    },
    cancelDialog() {
      this.dialogSelectVisible = false
    },

    gbDialog(list) {
      //搭配组件使用的方法,关闭弹出框
      this.dialogSelectVisible = false
      // console.log(list)
      for (let value of list.values()) {
        this.form.list.push(value)
      }
    },
    moveSaveClass(index) {
      // //点击移除
      // console.log(id)
      this.form.list.splice(index, 1)
    },
    onSubmit(formName) {
      this.$refs.Activityform.validate(valid => {
        // console.log(valid)
        if (valid) {
          // console.log(valid)
          if (this.form.list.length == 0) {
            this.$root.prompt({
              msg: '裂变活动至少要关联一门付费课程！',
              type: 'error',
            })
            return
          }
          // console.log(formName.condition)
          //点击保存 转换数据
          // console.log(formName)
          //开始时间 接收时间戳
          let start_time = ''
          //结束时间 接收时间戳
          let end_time = ''

          //参与条件 接收1或2
          let join_condition = ''

          start_time = Date.parse(this.form.date1[0]) / 1000
          end_time = Date.parse(this.form.date2[0]) / 1000
          if (
            Date.parse(
              this.$options.filters['formatTimeStamp'](
                end_time,
                'yyyy-MM-dd hh:mm:00'
              )
            ) /
              1000 <=
            Date.parse(
              this.$options.filters['formatTimeStamp'](
                start_time,
                'yyyy-MM-dd hh:mm:00'
              )
            ) /
              1000
          ) {
            this.$root.prompt({
              msg: '活动结束时间不能小于和等于开始时间！',
              type: 'error',
            })
            return
          }
          if (formName.condition == '无需报名课程') {
            join_condition = '1'
          } else {
            join_condition = '2'
          }

          console.log(
            this.$route.params.id,
            formName.name,
            start_time,
            end_time,
            join_condition
          )
          if (this.$route.params.id == 'new') {
            this.$http({
              url: '/Fission/createFission',
              data: {
                name: formName.name,
                join_condition: join_condition,
                commission_ratio: formName.proportion,
                list: formName.list,
                start_time: start_time,
                end_time: end_time,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/marketingFission',
                })
              },
              error: data => {
                this.$root.prompt(data.info)
              },
            })
          } else {
            this.$http({
              url: '/Fission/editFission',
              data: {
                fission_id: this.$route.params.id,
                name: formName.name,
                join_condition: join_condition,
                commission_ratio: formName.proportion,
                list: formName.list,
                start_time: start_time,
                end_time: end_time,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/marketingFission',
                })
              },
              error: data => {
                this.$root.prompt(data.info)
              },
            })
          }
        } else {
          return false
        }
      })
    },
    getMsgData(id) {
      this.form.date1.pop()
      this.form.date2.pop()
      this.$http({
        url: '/Fission/moreFissionCourse',
        data: {
          fission_id: id,
        },
        callback: ({ data }) => {
          // console.log(data)
          //拿到数据后赋值
          this.form.name = data.name
          this.form.date1.push(
            this.$options.filters['formatTimeStamp'](
              data.start_time,
              'yyyy-MM-dd hh:mm'
            )
          )
          this.form.date2.push(
            this.$options.filters['formatTimeStamp'](
              data.end_time,
              'yyyy-MM-dd hh:mm'
            )
          )
          this.form.condition =
            data.join_condition == 1 ? '无需报名课程' : '需报名课程'
          // console.log(data.join_condition,this.form.condition)
          this.form.proportion = data.commission_ratio
          this.form.list = data.list
          this.form.status = data.status
          // this.from.fission_id = data.fission_id
          // console.log(this.form)
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  transform-origin: left !important;
}

p,
span {
  color: #333;
}

::v-deep .el-popconfirm__main {
  margin-bottom: 10px;
}

.newActivityBoxAll {
  padding-bottom: 40px;
  background: #fff;
  height: 100%;
  min-height: 660px;
}

.activityMsgBox {
  margin-left: 20px;
  margin-top: 40px;

  .el-input {
    width: 618px;
  }

  .spanProportion {
    width: 11px;
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 16px;
    margin-left: 10px;
  }

  .activityTime {
    margin-top: 30px;
    display: flex;

    .dateBox {
      display: flex;
      width: 232px;
    }

    .dateBoxInput {
      width: 200px;

      .el-input {
        width: 200px;
      }

      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .dateBoxZ {
      width: 12px;
      height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #333333;
      margin: 0 10px;
    }
  }

  .activityJoin {
    margin-top: 30px;

    .el-form-item {
      margin-bottom: 15px;
    }

    .activityJoinChild {
      width: 618px;
      height: 56px;
      background: #fff5f5;
      border-radius: 1px;
      margin-left: 130px;
      overflow: hidden;

      p {
        width: 446px;
        height: 32px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #f36767;
        line-height: 16px;
        margin-top: 11px;
        margin-left: 12px;
      }
    }
  }

  .activityProportion {
    margin-top: 30px;

    .el-form-item {
      margin-bottom: 18px;
    }

    p {
      width: 626px;
      height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 12px;
      margin-left: 130px;
    }
  }
}

.activityListBox {
  margin-top: 44px;
  .tuwenSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }

  ::v-deep .el-button {
    padding: 12px 26px;
  }

  .title::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }

  .title {
    display: flex;
    margin: 0 20px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;

    h3 {
      width: 87px;
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }

    p {
      width: 312px;
      height: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 16px;
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  .selectButton {
    width: 126px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #0aa29b;
    text-align: center;
    margin-left: 20px;
    margin-top: 30px;
    cursor: pointer;

    p {
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #0aa29b;
      line-height: 42px;
    }
  }

  .activityList {
    margin: 0 20px;

    // width: 852px;
    .noData {
      height: 60px;
      margin: 0 auto;
      text-align: center;

      p {
        color: #909399;
        font-size: 14px;
        line-height: 60px;
      }
    }

    .list_1 {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .el-button {
        padding: 0;
      }

      .imgTitle {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          width: 40px;
          height: 22.5px;
        }

        p {
          margin-left: 10px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 22.5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .menuRight {
        display: flex;
        min-width: 250px;
        justify-content: space-between;
        margin-left: 40px;

        .p {
          width: 39px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 22.5px;
          margin-top: 2px;
        }

        .span {
          width: 47px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #ff3535;
          line-height: 22.5px;
          margin-top: 2px;
        }

        a {
          width: 26px;
          height: 13px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #1b9d97;
          line-height: 22.5px;
          cursor: pointer;
        }
      }
    }
  }
}

.buttonSave {
  margin-top: 41px;
  margin-left: 20px;

  .el-button--primary {
    width: 126px;
  }
}
</style>
